import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { seo_title, full_title, title, redirect, tags, announce, og, schemaData, description },
      body
    }
  }
}) => {
  if (redirect) {
    navigate(redirect);
  }

  const schemaTitle = title ? title.replace(/&nbsp;/g, ' ').replace(/&mdash;/g, '—') : '';

  let schemaObj = schemaData
    ? [
        {
          '@context': 'http://schema.org',
          '@type': 'Service',
          serviceType: schemaTitle ? schemaTitle : null,
          'schema:provider': {
            '@type': 'schema:Organization',
            'schema:name': schemaData['organization'] ? schemaData['organization'] : null
          },
          offers: {
            '@type': 'AggregateOffer',
            lowPrice: schemaData['lowPrice'] ? schemaData['lowPrice'] : null,
            highPrice: schemaData['highPrice'] ? schemaData['highPrice'] : null,
            priceCurrency: 'RUB'
          }
        }
      ]
    : null;

  if (schemaData && schemaData['serviceList']) {
    let serviceArray = [];

    schemaData['serviceList'].forEach(item => {
      serviceArray.push({
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: item['name'] ? item['name'] : null
          // Не поддерживает эти свойства тип service
          // 'price': item['price'] ? item['price'] : null,
          // 'priceCurrency': schemaData['priceCurrency'] ? schemaData['priceCurrency'] : null
        }
      });
    });

    schemaObj['hasOfferCatalog'] = {
      '@type': 'OfferCatalog',
      name: schemaTitle ? schemaTitle : null,
      itemListElement: serviceArray
    };
  }

  if (schemaData && schemaData['breadcrumbs']) {
    let breadcrumbsArray = [];
    let level = 1;

    schemaData['breadcrumbs'].forEach(item => {
      breadcrumbsArray.push({
        '@type': 'ListItem',
        position: level++,
        item: {
          '@id': item['url'] ? item['url'] : null,
          name: item['name'] ? item['name'] : null
        }
      });
    });

    schemaObj.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbsArray
    });
  }

  return (
    <>
      <Seo title={seo_title || full_title || title} og={og} schema={schemaObj} description={description} />
      <Detail title={full_title || title} tags={tags ? tags.split(',') : []} announce={announce}>
        {body}
      </Detail>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        title
        full_title
        seo_title
        redirect
        tags
        announce
        og
        description
        schemaData {
          organization
          serviceList {
            name
          }
          breadcrumbs {
            url
            name
          }
        }
      }
      body
    }
  }
`;
